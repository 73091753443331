<template>
  <TemplateFormComp />
</template>

<script>
import TemplateFormComp from "@/layouts/TemplateFormComp.vue";

export default {
  name: "CarForm",
  components: { TemplateFormComp },
};
</script>
