<template>
  <div class="content md flex-box vertical temp-detail" v-if="info">
    <div class="temp-detail-tabs">
      <div
          class="temp-tab-item"
          :class="{active: key == tab.key}"
          :key="tab.key"
          @click="changeTab(tab.key)"
          v-for="tab in tabs">
        <div class="temp-tab-title">{{tab.title}}</div>
      </div>
    </div>
    <div class="temp-detail-ctx">
      <div class="temp-set" v-show="key == 1">
        <form-area
          ref="tempForm"
          class="temp-set-form"
          layout="horizontal"
          :label-col="{span: 4}"
          :wrapper-col="{span: 20}"
          :items="items"
          :entity="form"
          hide-btn
          v-show="edit"></form-area>
        <div class="temp-set-info" v-show="!edit">
          <div class="set-info-item" v-for="item in items" :key="item.key">
            <div class="set-info-label">{{item.label}}</div>
            <div class="set-info-ctx">
              <div class="set-temp-icon" v-if="item.key == 'icon'">
                <img :src="base + info.icon" class="temp-icon-img">
              </div>
              <template v-else-if="item.key == 'extraTemp'">
                <child-temp :value="extraTemp" :edit="false" v-if="extraTemp && extraTemp.length > 0"></child-temp>
                <span v-else>无</span>
              </template>
              <span v-else-if="item.key === 'type'">{{getTempName(info.type)}}</span>
              <span v-else-if="item.key === 'oa_type'">{{getTempGroupName(info.oa_type)}}</span>
              <span v-else>{{info[item.key]}}</span>
            </div>
          </div>
        </div>
      </div>
      <custom-form
        class="form-edit"
        ref="formEdit"
        hide-btn
        :editable="editable"
        :dev="dev == 1"
        :list="formControls"
        :edit.sync="edit" v-show="key == 2"></custom-form>
    </div>
    <a-space class="footer" v-show="key == 1 || editable">
      <template v-if="edit">
        <a-button @click="cancel">取消</a-button>
        <a-button type="primary" @click="save">保存</a-button>
      </template>
      <a-button type="primary" @click="editTemp" v-else>编辑</a-button>
    </a-space>
  </div>
</template>

<script>
import {
    formFixedTempTypes,
    getTempName,
    getTempGroupName,
    getTempFormItems
} from "@/common/constant/template";
import customForm from "@/components/custom-form/custom-form.vue";
import {base} from "@/components/icon-select";
import {clone} from "@/common/js/tool";

export default {
  name: "TemplateFormComp",
  components: {
    customForm,
  },
  data() {
    return {
      formControls: [],
      formItems: [],
      edit: false,
      form: {},
      key: 1,
      items: [],
      extraTemp: [],
      base,
      tabs: [
        {title: '应用设置', key: 1},
        {title: '表单配置', key: 2},
      ]
    }
  },
  computed: {
    // 调试模式 用于固定表单字段修改
    dev() {
      return this.$route.query.d;
    },
    info() {
      let temp = this.$store.getters.temp;
      if(temp) {
        temp.formFixed = formFixedTempTypes.indexOf(temp.type) >= 0;
      }
      return temp;
    },
    editable() {
      if(this.info) {
        return !(this.info.formFixed && this.dev != 1);
      } else {
        return false;
      }
    }
  },
  watch: {
    key() {
      this.cancel();
    },
    info(val) {
      this.dealInfo(val)
    }
  },
  created() {
    this.dealInfo(this.info);
  },
  methods: {
    getTempName,
    getTempGroupName,
    setProcess() {
      this.$store.commit("setDetail", {type: 'temp', obj: this.info});
      this.$router.push({name: 'Process', params: {id: this.info.id}});
    },
    changeTab(activeKey) {
      if(this.edit) {
        this.$message.warning("请先退出编辑状态！");
        return;
      }
      this.key = activeKey;
    },
    cancel() {
      let key = this.key;
      if(key == 2) {
        this.$refs.formEdit.cancel();
      } else {
        this.edit = false;
      }
    },
    save() {
      let key = this.key;
      if(key == 1) {
        this.$refs.tempForm.handleConfirm().then(form => {
          this.updateTemp({
            name: form.name,
            icon: form.icon,
            type: form.type,
            oa_type: form.oa_type,
            extra_temp_id: form.extraTemp && form.extraTemp.length > 0 ? form.extraTemp.map(t => t.id).join(",") : null
          })
        });
      } else if(key == 2) {
        this.$refs.formEdit.saveForm().then(form => {
          this.updateTemp({detail: form});
        })
      }
    },
    updateTemp(data) {
      let url = `/admin/apply-temp/${this.info.id}`;
      this.$axios({
        url,
        method: "PATCH",
        data
      }).then(res => {
        let temp = clone(this.info);
        temp = Object.assign(temp, res);
        this.$store.commit("setTemp", temp);
        this.$message.success("更新成功");
        this.cancel();
      });
    },
    editTemp() {
      let key = this.key;
      if(key == 1) {
        let info = this.info;
        this.form = {
          id: info.id,
          name: info.name,
          type: info.type,
          oa_type: info.oa_type,
          icon: info.icon,
          extraTemp: info.extraTemp
        }
        this.edit = true;
      } else if(this.editable) {
        this.edit = true;
      }
    },
    dealInfo(info) {
      if(info) {
        if(info.detail) {
          try {
            let form = JSON.parse(info.detail);
            this.formItems = form;
            this.formControls = clone(form);
          } catch (e) {
            this.formItems = [];
            console.error(e);
          }
        }
        let items = getTempFormItems(info);
        let con = items.find(c => c.key == 'temps');
        if(con) {
          con.props.current = info.id;
        }
        this.items = items;
      }
      this.extraTemp = info && info.extraTemp ? info.extraTemp : [];
    },
  }
}
</script>

<style lang="less">
.temp-detail {
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
}
.temp-detail-tabs {
  display: flex;
  border-bottom: var(--border);
}
.temp-tab-item {
  position: relative;
  padding: 12px 16px;
  &:not(:first-child) {
    margin-left: 24px;
  }
  cursor: pointer;
  &.active {
    color: @primary-color;
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -1px;
      height: 2px;
      border-radius: 2px;
      background-color: @primary-color;
    }
  }
}
.temp-detail-ctx {
  flex: 1;
  padding-top: 16px;
  min-height: 0;
}
.set-temp-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 4px;
  border: var(--border);
  .temp-icon-img {
    width: 32px;
    height: 32px;
  }
}
.set-info-label {
  min-width: 100px;
  color: @text-color-secondary;
}
.temp-set {
  height: 100%;
}
.temp-set-form {
  height: 100%;
}
.temp-detail-rule {
  padding-bottom: 16px;
  height: 100%;
  overflow: auto;
}
</style>
